@keyframes Blink {
    0% { transform: scaleY(1); }
    2% { transform: scaleY(0.1); }
    4% { transform: scaleY(1); }
}

@keyframes dash {
    from {
        stroke-dashoffset: 822;
    }
    to {
        stroke-dashoffset: 0;
    }
}

.BowltSudiosLogo {
    .eye {
        transform-origin: 50% 550%;
        animation: Blink 4s infinite;
    }

    .eye-iris {
        transition: transform 0.2s;
    }
}