.App {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;

  .ComingSoon {
    font-size: 2rem;
    font-family: Verdana, sans-serif;
    color: dodgerblue;
  }
}

@media screen and (max-width: 700px) {
  .App {
    flex-direction: column;
  }
}